<template>
  <span>
    <h3 class="text-h6 font-weight-bold mt-2 mb-2">Tags</h3>

    <v-list dense>
      <v-list-item v-for="tag in result.items" :key="tag.id" class="mb-2">
        <div
          class="pa-1 w-100 teal--text"
          style="background-color: #e0f2f1; border-radius: 13px"
        >
          <v-card-title class="d-flex align-center">
            <v-icon class="mr-2" color="teal darken-1">mdi-tag-text</v-icon>
            <a
              :href="`/accounting/tag/${tag.name}`"
              target="_blank"
              class="font-weight-bold"
              style="font-size: 1rem"
            >
              #{{ tag.name }}
            </a>
          </v-card-title>

          <v-card-subtitle
            class="d-flex justify-space-between align-center mt-1"
          >
            <div class="d-flex flex-column">
              <span>
                Created On:
                <b>
                  {{ tag.created_at | humanDate }}
                </b>
              </span>
            </div>
          </v-card-subtitle>
        </div>
      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
