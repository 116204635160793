<template>
  <v-col style="height: 90px" cols="10">
    <v-text-field
        clearable
        filled
        rounded
        type="search"
        class="mt-2"
        placeholder="Search for anything"
        v-model="keyword"
        id="general-search"
        autocomplete="off"
        @input="debouncedSearch"
        @click="showSheet = true"
        @keydown.enter="search"
    >
      <template v-slot:prepend-inner v-if="![null, undefined].includes(model)">
        <div style="margin-top: 2px">
          In {{ filterOptions[model].prependText }}:
        </div>
      </template>

      <template v-slot:append>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-badge
                color="error"
                overlap
                dot
                :value="![null, undefined].includes(model)"
            >
              <v-icon v-bind="attrs" v-on="on" @click="showSheet = false"
              >mdi-tune
              </v-icon
              >
            </v-badge>
          </template>

          <v-list style="max-height: 300px; overflow-y: auto">
            <v-list-item-group v-model="model">
              <v-list-item v-for="(item, index) in filterOptions" :key="index">
                <v-list-item-action>
                  <input
                      type="checkbox"
                      :checked="model === index"
                      style="width: 20px; height: 20px; cursor: pointer"
                      @change="showSheet = true"
                  />
                </v-list-item-action>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>

    <v-sheet
        v-if="showSheet"
        style="
        max-height: 500px;
        overflow-y: auto;
        padding: 16px;
        border-radius: 8px;
        margin-top: -25px;
      "
        id="general-search-sheet"
        class="elevation-2"
    >
      <div
          v-if="shouldShowNoResults"
          class="text-center"
          id="general-search-sheet"
      >
        <v-img
            src="/img/panda.png"
            max-width="80px"
            alt="Panda"
            class="mx-auto"
            id="general-search-sheet"
        ></v-img>
        <div class="headline my-2" id="general-search-sheet">
          No search results
        </div>
        <div class="text-gray-600" id="general-search-sheet">
          Could not find any search results matching <b>"{{ keyword }}"</b>
        </div>
      </div>
      <div
          v-if="
          !searching && (!results || keyword === '')
        "
          class="text-center"
          id="general-search-sheet"
      >
        <v-img
            src="/img/panda.png"
            max-width="80px"
            alt="Panda"
            class="mx-auto"
            id="general-search-sheet"
        ></v-img>
        <div class="headline my-2" id="general-search-sheet">
          Search for anything!
        </div>
        <div class="text-gray-600" id="general-search-sheet">
          Quickly access your accounting records, transactions, and financial
          reports in one place.
        </div>
      </div>
      <div
          v-if="searching"
          class="d-flex justify-center align-center"
          style="height: 100%"
      >
        <div class="d-flex align-center">
          <span
          >Showing results for <b>{{ keyword }}</b></span
          >
          <v-progress-circular
              indeterminate
              size="24"
              color="primary"
              class="ml-2"
          ></v-progress-circular>
        </div>
      </div>
      <div v-if="!searching && !shouldShowNoResults && keyword !== ''">
        <div v-for="result in results" :key="result.name">
          <!-- Render Invoices -->
          <div v-if="result.name === 'Invoices'">
            <invoices :result="result"></invoices>
          </div>

          <!-- Render Expenses -->
          <div v-if="result.name === 'Expenses'">
            <expenses :result="result"></expenses>
          </div>

          <!-- Render Bills -->
          <div v-if="result.name === 'Bills'">
            <bills :result="result"></bills>
          </div>

          <!-- Render Witholding -->
          <div v-if="result.name === 'Withholding Tax Payments'">
            <witholding :result="result"></witholding>
          </div>

          <!-- Render Income Payments -->
          <div v-if="result.name === 'Income Tax Payments'">
            <income :result="result"></income>
          </div>

          <!-- Render Sales Tax Payments -->
          <div v-if="result.name === 'Sale Tax Payments'">
            <sales-tax :result="result"></sales-tax>
          </div>

          <!-- Render Suppliers -->
          <div v-if="result.name === 'Suppliers'">
            <suppliers :result="result"></suppliers>
          </div>

          <!-- Render Quick Sales -->
          <div v-if="result.name === 'Quick Sales'">
            <quick-sales :result="result"></quick-sales>
          </div>

          <!-- Render Quotes -->
          <div v-if="result.name === 'Quotes'">
            <quotes :result="result"></quotes>
          </div>

          <!-- Render deposits -->
          <div v-if="result.name.toLowerCase() === 'deposits'">
            <deposits :result="result"></deposits>
          </div>

          <!-- Render Customers -->
          <div v-if="result.name === 'Customers'">
            <customers :result="result"></customers>
          </div>

          <!-- Render Products -->
          <div v-if="result.name === 'Products & Services'">
            <products :result="result"></products>
          </div>

          <!-- Render Bank Transfers -->

          <div v-if="result.name === 'Bank Transfers'">
            <bank-transfers :result="result"></bank-transfers>
          </div>

          <!-- Render Journals -->

          <div v-if="result.name === 'Journals'">
            <journals :result="result"></journals>
          </div>

          <!-- Render Loans -->

          <div v-if="result.name === 'Loans'">
            <loans :result="result"></loans>
          </div>

          <!-- Render Accounts -->

          <div v-if="result.name === 'Accounts'">
            <accounts :result="result"></accounts>
          </div>

          <!-- Render Tags -->

          <div v-if="result.name === 'Tags'">
            <tags :result="result"></tags>
          </div>

          <!-- Render Sync Expenses -->

          <div v-if="result.name === 'Sync Expenses'">
            <sync-expenses :result="result"></sync-expenses>
          </div>
          <div v-if="result.name === 'Sync Incomes'">
            <sync-income :result="result"></sync-income>
          </div>
        </div>
      </div>
    </v-sheet>
  </v-col>
</template>

<script>
import {debounce} from "lodash";
import Invoices from "./Invoices.vue";
import Expenses from "./Expenses.vue";
import Bills from "./Bills.vue";
import Witholding from "./Witholding.vue";
import Income from "./Income.vue";
import SalesTax from "./SalesTax.vue";
import Suppliers from "./Suppliers.vue";
import QuickSales from "./QuickSales.vue";
import Quotes from "./Quotes.vue";
import Customers from "./Customers.vue";
import Products from "./Products.vue";
import BankTransfers from "./BankTransfers.vue";
import Journals from "./Journals.vue";
import Loans from "./Loans.vue";
import Accounts from "./Accounts.vue";
import Tags from "./Tags.vue";
import SyncExpenses from "./SyncExpenses.vue";
import SyncIncome from "./SyncIncome.vue";
import Deposits from "./Deposits.vue";

export default {
  components: {
    Deposits,
    Invoices,
    Expenses,
    Bills,
    Witholding,
    Income,
    SalesTax,
    Suppliers,
    QuickSales,
    Quotes,
    Customers,
    Products,
    BankTransfers,
    Journals,
    Loans,
    Accounts,
    Tags,
    SyncExpenses,
    SyncIncome,
  },
  data() {
    return {
      showSheet: false,
      results: null,
      items: [
        {title: "Click Me"},
        {title: "Click Me"},
        {title: "Click Me"},
        {title: "Click Me 2"},
      ],
      filterOptions: [
        {text: "Invoices", value: "invoices", prependText: "invoices"},
        {text: "Expenses", value: "expenses", prependText: "expenses"},
        {text: "Bills", value: "bills", prependText: "bills"},
        {
          text: "Withholding Tax Payments",
          value: "withholding_tax_payments",
          prependText: "withholding",
        },
        {
          text: "Income Tax Payments",
          value: "income_tax_payments",
          prependText: "income tax",
        },
        {
          text: "Sale Tax Payments",
          value: "sale_tax_payments",
          prependText: "sales tax",
        },
        {text: "Suppliers", value: "suppliers", prependText: "suppliers"},
        {
          text: "Quick Sales",
          value: "quick_sales",
          prependText: "quick sales",
        },
        {text: "Quotes", value: "quotes", prependText: "quotes"},
        {text: "Customers", value: "customers", prependText: "customers"},
        {text: "Products", value: "products", prependText: "products"},
        {
          text: "Bank Transfers",
          value: "bank_transfers",
          prependText: "bank transfers",
        },
        {text: "Journals", value: "journals", prependText: "journals"},
        {text: "Loans", value: "loans", prependText: "loans"},
        {text: "Accounts", value: "accounts", prependText: "accounts"},
        {
          text: "Sync Incomes",
          value: "syncIncomes",
          prependText: "sync incomes",
        },
        {text: "Tags", value: "tags", prependText: "tags"},
        {
          text: "Sync Expenses",
          value: "syncExpenses",
          prependText: "sync expenses",
        },
      ],
      hasModelSelected: false,
      keyword: "",
      model: undefined,
      searching: false,
    };
  },
  methods: {
    search() {
      this.results = null;
      this.searching = true;
      let params = `keyword=${this.keyword || ""}`;
      if (![null, undefined].includes(this.model)) {
        params += `&model=${this.filterOptions[this.model].value}`;
      }
      axios
          .get(`/api/v2/search?${params}`)
          .then((res) => {
            this.results = res.data;
          })
          .catch((error) => {
            this.results = [];
          })
          .finally(() => {
            this.searching = false;
          });
    },
    handleClickOutside(event) {
      const targetId = event.target.id;
      if (!["general-search", "general-search-sheet"].includes(targetId)) {
        this.showSheet = false;
      }
    },
  },
  computed: {
    shouldShowNoResults() {
      return (
          this.results &&
          this.results?.length > 0 &&
          this.results.every((item) => item.items?.length === 0) &&
          !this.searching
      );
    },
  },
  watch: {
    model(val) {
      if (this.results && this.results.length > 0) {
        this.search();
      }
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 1000);
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped></style>
