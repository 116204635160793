<template>
  <span>
    <v-subheader>Products & Services</v-subheader>

    <v-list dense>
      <v-list-item
        v-for="product in result.items"
        :key="product.id"
        class="mb-2 text-decoration-none rounded-lg"
        :to="`/inventory/${product.id}`"
      >

        <v-list-item-avatar rounded :size="60">
          <v-img :src="product.photo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ product.name }} - {{ product.code }}</v-list-item-title>
          <v-list-item-subtitle>Category: {{ product.category.name }}</v-list-item-subtitle>
          <v-list-item-subtitle>Quantity: {{ product.quantity}}</v-list-item-subtitle>
          <v-list-item-subtitle>Price: {{ product.selling_price | toMoney | currency_symbol }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon small color="grey lighten-2">mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
