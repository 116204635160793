<template>
  <span>
    <h3 class="text-h6 font-weight-bold mt-2 mb-2">Journals</h3>

    <v-list dense>
      <v-list-item
        v-for="journal in result.items"
        :key="journal.id"
        class="mb-2"
      >
        <div
          class="pa-1 w-100 brown--text"
          style="background-color: #efebe9; border-radius: 13px"
        >
          <v-card-title class="d-flex align-center">
            <v-icon class="mr-2" color="brown darken-1">mdi-calendar</v-icon>
            <a
              :href="`/accounting/journal/${journal.id}?itemid=${journal.id}`"
              target="_blank"
              class="font-weight-bold"
              style="font-size: 1rem"
            >
              {{ journal.description.slice(0, 30) }}
            </a>
          </v-card-title>

          <v-card-subtitle
            class="d-flex justify-space-between align-center mt-1"
          >
            <span>
              Entry Date:
              <b>
                {{ journal.entry_date | humanDate }}
              </b>
            </span>
            <v-chip
              style="background-color: #d7ccc8"
              class="brown--text font-weight-bolder"
              small
              v-if="journal.tag !== ''"
            >
              {{ journal.tag.split(",")[0] }}
            </v-chip>
          </v-card-subtitle>
        </div>
      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
