<template>
  <span>
    <v-subheader>Expenses</v-subheader>

    <v-list dense>
      <v-list-item
          color="red"
        v-for="expense in result.items"
        :key="expense.id"
        class="mb-2 text-decoration-none"
        :to="`/expense/paidexpenses/${expense.id}`"
      >

        <v-list-item-avatar rounded="lg" size="70" color="red lighten-5">
            <v-icon color="red darken-1">mdi-cash-minus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ expense.serial_number }} - {{ expense.expense_account.account_name }}</v-list-item-title>
          <v-list-item-subtitle>Date: {{ expense.entry_date | humanDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>Paid from: {{ expense.payment_account.account_name }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{expense.description}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
