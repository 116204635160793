<template>
  <span>
    <v-subheader>Accounts</v-subheader>

    <v-list dense>
      <v-list-item
        v-for="account in result.items"
        :key="account.id"
        class="mb-2 text-decoration-none"
        :to="`/transaction/${account.id}/${today}/null`"
      >
        <v-list-item-avatar color="blue lighten-5" rounded="lg">
          <v-icon color="blue">mdi-bank-outline</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{account.account_name}}
          </v-list-item-title>
          <v-list-item-subtitle>Balance: {{ account.balance | toMoney | currency_symbol }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
             <v-chip
                 class="blue--text font-weight-bolder"
                 small
                 color="blue lighten-5"
             >
              {{ account.type.name }}
            </v-chip>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>

        </v-list-item-action-text>
      </v-list-item>
    </v-list>
  </span>
</template>

<script>
import AccountButton from "@/components/patials/accountButton.vue";
import moment from "moment";

export default {
  components: {AccountButton},
  props: ["result"],
  computed:{
    today(){
      return moment().format("Y-M-D");
    }
  }
};
</script>
