<template>
  <span>
    <v-subheader>Invoices</v-subheader>

    <v-list dense>
      <v-list-item
          v-for="invoice in result.items"
          :key="invoice.id"
          class="mb-2 text-decoration-none rounded-lg"
          :to="`/income/invoices/${invoice.id}`"
      >

        <v-list-item-avatar size="50" rounded="lg" color="blue lighten-5">
            <v-icon color="blue">mdi-receipt-text</v-icon>
          </v-list-item-avatar>
        <v-list-item-content>

          <v-list-item-title>
             {{ invoice.invoice_number }} - {{ invoice.customer.business_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Amount Due: {{ invoice.fx_currency }} {{ invoice.amount_due | toMoney }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>Issue Date: {{invoice.start_date | humanDate}}, Due Date: {{invoice.end_date | humanDate}}</v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action-text>
             <v-chip
                 style="background-color: rgba(255, 0, 0, 0.08)"
                 class="text-danger font-weight-bolder"
                 small
                 v-if="invoice.paid_status == 'Not Paid'"
             >
              {{ $t("main.not_paid") }}
            </v-chip>

            <v-chip
                style="background-color: rgba(255, 255, 0, 0.08)"
                class="text-warning font-weight-bolder"
                small
                v-if="invoice.paid_status == 'Paid Part'"
            >
              {{ $t("main.paid_part") }}
            </v-chip>

            <v-chip
                style="background-color: rgba(0, 128, 0, 0.08)"
                class="text-success font-weight-bolder"
                small
                v-if="invoice.paid_status == 'Paid'"
            >
              {{ $t("main.paid") }}
            </v-chip>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>

        </v-list-item-action-text>

      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
